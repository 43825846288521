<template>
  <div>
    <!-- Si es un concepto manual -->
    <EditConceptoDesregulacion
      v-if="esConceptoManual"
      :conceptoDesregulacion="detalleConcepto"
      :verConcepto="true"
      @closeAndReload="closeModal"
    ></EditConceptoDesregulacion>
    <!-- Si es un concepto automatico -->
    <v-card v-else>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card-title>
              <span class="pl-1 primary--text">{{ titleVer }}</span>
            </v-card-title>
            <v-card>
              <!-- Tabla -->
              <v-data-table
                :headers="headers[this.tipoProcesoXHeader[tipoProceso]]"
                :items="items"
                class="elevation-1"
                :search="search"
                :loading="isLoading"
                :sort-desc="true"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="10">
                        <v-text-field
                          v-model="search"
                          :append-icon="searchIcon"
                          label="Buscar"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined @click="closeModal"> Cerrar </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import EditConceptoDesregulacion from "@/components/modules/convenios/procesosDesregulacion/EditConceptoDesregulacion.vue";

export default {
  name: "VerConceptoProcesoDesreg",
  components: {
    EditConceptoDesregulacion
  },
  props: {
    liqDesDetaId: {
      type: Number,
      required: true
    },
    esConceptoManual: {
      type: Boolean,
      require: true,
      default: true
    },
    tipoProceso: {
      type: String,
      default: null
    },
    detalleConcepto: {
      type: Object,
      require: false
    }
  },
  data: vm => ({
    titleVer: enums.titles.VER_CONCEPTO_PROCESO_DESREG,
    isLoading: false,
    search: "",
    searchIcon: enums.icons.SEARCH,
    items: [],
    headers: {
      AportesFiscalizacion: [
        {
          text: "CUIT",
          value: "cuit",
          sortable: false,
          align: "start"
        },
        {
          text: "CUIL",
          value: "cuil",
          sortable: false,
          align: "start"
        },
        {
          text: "Fecha",
          value: "fecha",
          sortable: false,
          align: "start"
        },
        {
          text: "Aporte",
          value: "aporte",
          sortable: false,
          align: "end"
        },
        {
          text: "Contribucion",
          value: "contribucion",
          sortable: false,
          align: "end"
        },
        {
          text: "Otros",
          value: "otros",
          sortable: false,
          align: "end"
        },
        {
          text: "Periodo",
          value: "periodo",
          sortable: true,
          align: "start"
        },
        {
          text: "Nombre",
          value: "nombre",
          sortable: true,
          align: "start"
        },
        {
          text: "Grupo",
          value: "grupo",
          sortable: false,
          align: "start"
        },
        {
          text: "Empresa",
          value: "empresa",
          sortable: false,
          align: "start"
        }
      ],
      CapitaAnses: [
        {
          text: "CUIL",
          value: "cuil",
          sortable: false,
          align: "start"
        },
        {
          text: "Fecha",
          value: "fecha",
          sortable: false,
          align: "start"
        },
        {
          text: "Importe",
          value: "importe",
          sortable: false,
          align: "end"
        },
        {
          text: "PerÍodo",
          value: "periodo",
          sortable: false,
          align: "start"
        },
        {
          text: "Nombre",
          value: "apellidoyNombre",
          sortable: false,
          align: "start"
        },
        {
          text: "Grupo",
          value: "grupo",
          sortable: false,
          align: "start"
        }
      ],
      IntegracionDebitoSur: [
        {
          text: "CUIL",
          value: "cuil",
          sortable: false,
          align: "start"
        },
        {
          text: "CUIT",
          value: "cuit",
          sortable: false,
          align: "start"
        },
        {
          text: "Origen",
          value: "origen",
          sortable: true,
          align: "start"
        },
        {
          text: "Número de expediente",
          value: "nroExpediente",
          sortable: true,
          align: "start"
        },
        {
          text: "Número de orden",
          value: "nroOrden",
          sortable: true,
          align: "start"
        },
        {
          text: "Orden de pago",
          value: "ordenPago",
          sortable: true,
          align: "start"
        },
        {
          text: "Número de liquidación",
          value: "nroLiquidacion",
          sortable: true,
          align: "start"
        }
      ],
      Prestaciones: [
        {
          text: "CUIL",
          value: "cuil",
          sortable: false,
          align: "start"
        },
        {
          text: "Origen",
          value: "origen",
          sortable: true,
          align: "start"
        },
        {
          text: "Número de expediente",
          value: "nroExpediente",
          sortable: true,
          align: "start"
        },
        {
          text: "Número de orden",
          value: "nroOrden",
          sortable: true,
          align: "start"
        },
        {
          text: "Orden de pago",
          value: "ordenPago",
          sortable: true,
          align: "start"
        }
      ]
    },
    tipoProcesoXHeader: {
      APORTE: "AportesFiscalizacion",
      FISCALIZACION: "AportesFiscalizacion",
      CAPITAANSES: "CapitaAnses",
      DEBITOINTEGRACION: "IntegracionDebitoSur",
      DEBITOSUR: "IntegracionDebitoSur",
      PRESTACIONES: "Prestaciones"
    }
  }),
  created() {
    this.loadProcesoDesregulacion();
  },
  methods: {
    ...mapActions({
      getDetalleConceptoAutomatico: "convenios/getDetalleConceptoAutomatico",
      setAlert: "user/setAlert"
    }),
    async loadProcesoDesregulacion() {
      this.isLoading = true;
      try {
        if (!this.esConceptoManual) {
          const res = await this.getDetalleConceptoAutomatico(
            this.liqDesDetaId
          );
          if (res.status == 200) {
            this.items = res.data.data;
            this.items.forEach(element => {
              // elementos que pueden existir o no dependiendo del tipo de proceso
              if (element.apellidoYNombre) element.apellidoYNombre = element.apellidoYNombre.trim()
              if (element.empresa) element.empresa = element.empresa.trim()             
              if (element.nroOrden) element.nroOrden = element.nroOrden.trim()
              if (element.origen) element.origen = element.origen.trim()
              if (element.ordenPago) element.ordenPago = element.ordenPago.trim()              
              // elementos en comun entre los tipos de procesos
              element.cuil = element.cuil.trim();
            }); 
            this.isLoading = false;
          }
        }
      } catch {
        this.isLoading = false;
      }
    },

    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped>
.fontColor {
  font-weight: bold !important;
  color: rgb(0, 0, 0) !important;
  text-align: center;
}
.invalidConfigText {
  color: red;
}
.fontsize {
  font-size: 12px;
}
.no-upper-case {
  text-transform: none;
}
</style>
