<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0 pb-0">
            <span class="pl-1 primary--text">{{ titleVer }}</span>
          </v-card-title>

          <v-card
            class="mx-auto my-12 mt-2 mb-4 pt-0"
            max-width="1200"
            outlined
          >
            <v-container class="pt-0">
              <v-row>
                <v-col cols="12" align="left" class="pt-0 pb-0">
                  <FiltersSelected
                    :filters="filtersApplied"
                    v-if="!showFilters"
                  />
                </v-col>
              </v-row>
              <v-expand-transition mode="out-in">
                <v-form
                  v-show="showFilters"
                  v-model="isFormValid"
                  ref="filters-form"
                  id="filters-form"
                >
                  <!-- Filtros comunes -->
                  <v-row>
                    <!-- Convenio -->
                    <v-col cols="4" md="5" class="pb-0">
                      <v-text-field
                        v-model="convenioSelected"
                        label="Convenio"
                        filled
                        disabled
                        readonly
                        outlined
                        clearable
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <!-- periodo -->
                    <v-col cols="3" md="3" class="pb-0">
                      <v-text-field
                        v-model="periodoProp"
                        label="Período"
                        outlined
                        filled
                        readonly
                        disabled
                        dense
                        clearable
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-expand-transition>
            </v-container>
          </v-card>
          <v-card>
            <v-data-table
              :headers="headers"
              :items="itemsRegistroProcesosDesreg"
              class="elevation-1"
              :search="search"
              :loading="isLoading"
              :sort-desc="true"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row class="mt-10">
                    <v-col cols="6">
                      <v-text-field
                        v-model="search"
                        :append-icon="searchIcon"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>

                    <v-col cols="2">
                      <v-text-field
                        outlined
                        dense
                        disabled
                        readonly
                        class="theme--light.v-input input right-input"
                        filled
                        label="Total importe"
                        v-model="totalImporte"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        outlined
                        dense
                        disabled
                        filled
                        readonly
                        class="theme--light.v-input input right-input"
                        label="Total comisión"
                        v-model="totalComision"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        outlined
                        dense
                        disabled
                        readonly
                        class="theme--light.v-input input right-input"
                        label="Total neto"
                        filled
                        v-model="totalNeto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="verConcepto(item)"
                      >
                        {{ seeIcon }}
                      </v-icon>
                    </template>
                    <span>Ver conceptos</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-col cols="3">
              <v-tooltip left max-width="40%">
                <template v-slot:activator="{ attrs }">
                  <v-btn
                    color="primary"
                    class="to-right fontsize"
                    v-bind="attrs"
                    outlined
                    @click="exportExcelModelo"
                  >
                    Exportar detalle
                  </v-btn>
                </template>
              </v-tooltip>
            </v-col>
            <v-btn outlined @click="closeModal"> Cerrar </v-btn>
          </v-card-actions>
          <v-dialog
            v-if="openModalverConcepto"
            v-model="openModalverConcepto"
            :max-width="esConceptoManual ? '40%' : '80%'"
            persistent
            @keydown.esc="openModalverConcepto = false"
          >
            <VerConceptoProcesoDesreg
              @closeAndReload="openModalverConcepto = false"
              :detalleConcepto="detalleConcepto"
              :liqDesDetaId="liqDesDetaId"
              :tipoProceso="tipoProcesoSelected"
              :esConceptoManual="esConceptoManual"
            ></VerConceptoProcesoDesreg>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import ConceptoProcesoDesregulacion from "@/components/modules/convenios/procesosDesregulacion/ConceptoProcesoDesregulacion.vue";
import VerConceptoProcesoDesreg from "@/components/modules/convenios/procesosDesregulacion/VerConceptoProcesoDesreg.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "VerProcesoDesregulacion",
  components: {
    FiltersSelected,
    ConceptoProcesoDesregulacion,
    VerConceptoProcesoDesreg
  },
  directives: { mask },
  props: {
    liqDesProcId: {
      type: Number,
      required: true
    },
    convenio: {
      type: String,
      default: null
    },
    periodo: {
      type: String,
      default: null
    }
  },
  data: vm => ({
    titleVer: enums.titles.VER_PROCESO_DESREG,
    seeIcon: enums.icons.SEE,
    searchIcon: enums.icons.SEARCH,
    totalImporte: 0,
    totalComision: 0,
    totalNeto: 0,
    isLoading: false,
    convenioSelected: null,
    convenios: [],
    dialog: false,
    isFormValid: false,
    filtersApplied: [],
    showFilters: true,
    search: "",
    periodoProp: "",
    openModalverConcepto: false,
    tipoProcesoSelected: null,
    esConceptoManual: null,
    detalleConcepto: {
      liqDesConc_Nom: null,
      liqDelconcAut_id: null,
      esVigente: null,
      signo: null,
      orden: null
    },
    liqDesDetaId: null,
    total: [],
    itemsRegistroProcesosDesreg: [],
    headers: [
      {
        text: "Concepto",
        value: "liqDesConcNom",
        sortable: false,
        align: "start"
      },
      {
        text: "Cuiles",
        value: "cuiles",
        sortable: false,
        align: "center"
      },
      {
        text: "Importe",
        value: "importe",
        sortable: false,
        align: "end"
      },
      {
        text: "Comisión",
        value: "comision",
        sortable: false,
        align: "end"
      },
      {
        text: "Neto",
        value: "neto",
        sortable: false,
        align: "end"
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ]
  }),
  created() {
    this.loadProcesoDesregulacion();
  },
  methods: {
    ...mapActions({
      getProcesosDeta: "convenios/getProcesosDeta",
      getConvenios: "afiliaciones/getConvenios",
      setAlert: "user/setAlert"
    }),
    async loadProcesoDesregulacion() {
      this.isLoading = true;
      try {
        const res = await this.getProcesosDeta(this.liqDesProcId);
        this.itemsRegistroProcesosDesreg = res.procesosDeta;
        this.totalComision = res.totales.totalComision;
        this.totalImporte = res.totales.totalImporte;
        this.totalNeto = res.totales.totalNeto;
        this.convenioSelected = this.convenio;
        this.periodoProp = this.periodo;
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    verConcepto(item) {
      this.openModalverConcepto = true;
      this.liqDesDetaId = item.liqDesDetaId;
      this.tipoProcesoSelected = item.tipoProceso.trim();
      this.esConceptoManual = item.esConcManual;
      this.detalleConcepto.liqDesConc_Nom = item.detalleConcepto.nombre.trim();
      this.detalleConcepto.liqDelconcAut_id =
        item.detalleConcepto.liqDesconcAutId;
      this.detalleConcepto.esVigente = item.detalleConcepto.esVigente;
      this.detalleConcepto.signo = item.detalleConcepto.signo;
      this.detalleConcepto.orden = item.detalleConcepto.orden;
    },
    exportExcelModelo() {
      let result = [];
      this.itemsRegistroProcesosDesreg.forEach(x =>
        result.push({
          ["Concepto"]: x.liqDesConcNom,
          ["Cuiles"]: x.cuiles,
          ["Importe"]: x.importeDecimal,
          ["Comisión"]: x.comisionDecimal,
          ["Neto"]: x.netoDecimal
        })
      );
      result.push({
        [""]: "",
        ["Total importe"]: this.totalImporte,
        ["Total comisión"]: this.totalComision,
        ["Total neto"]: this.totalNeto
      });
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Detalle proceso de desregulación");
    },
    closeModal() {
      this.$emit("closeModalVer");
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    }
  }
};
</script>

<style scoped>
.fontColor {
  font-weight: bold !important;
  color: rgb(0, 0, 0) !important;
  text-align: center;
}
.invalidConfigText {
  color: red;
}
.fontsize {
  font-size: 12px;
}
.no-upper-case {
  text-transform: none;
}
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
.right-input >>> input {
  text-align: right;
}
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}

</style>
