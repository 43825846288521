<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 py-0"
      >
        <v-row>
          <v-col cols="12" md="10">
            <PageHeader :title="title" />
          </v-col>
          <v-col cols="12" md="2" align="end" v-if="canConfig" class="mt-4">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  @click="goToConfigProcesoDesregulacion()"
                  ><v-icon size="30px">{{ settingsIcon }}</v-icon>
                </v-btn>
              </template>
              <span
                >Acceder a la configuración de procesos de desregulación</span
              >
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row justify="end">
              <v-col
                cols="1"
                align-self="center"
                align="center"
                class="pr-0 pl-0 pt-1"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                align="left"
                class="py-0"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
            </v-row>

            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <!-- Filtros comunes -->
                <v-row>
                  <!-- Concepto -->
                  <v-col cols="4" md="5" class="pb-0">
                    <v-autocomplete
                      v-model="convenioSelected"
                      :items="convenios"
                      label="Convenio"
                      item-text="value"
                      return-object
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>

                  <!-- Periodo Desde -->
                  <v-col cols="4" md="3" sm="12" class="pb-0 mt-0">
                    <v-text-field
                      dense
                      outlined
                      v-model="periodoDesde"
                      label="Período desde"
                      hint="Formato AAAAMM"
                      persistent-hint
                      type="text"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      v-mask="'######'"
                      :rules="
                        rules.required.concat(
                          [rules.validDateRange(periodoDesde, periodoHasta)],
                          rules.periodoYyyyMm
                        )
                      "
                    ></v-text-field>
                  </v-col>
                  <!-- Periodo Hasta -->
                  <v-col cols="4" md="3" sm="12" class="pb-0 mt-0">
                    <v-text-field
                      dense
                      outlined
                      v-model="periodoHasta"
                      label="Período hasta"
                      type="text"
                      hint="Formato AAAAMM"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      persistent-hint
                      v-mask="'######'"
                      :rules="
                        rules.required.concat(
                          [rules.validDateRange(periodoDesde, periodoHasta)],
                          rules.periodoYyyyMm
                        )
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <v-col cols="12" md="4" sm="6" class="py-0 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsProcesosDesreg"
            class="elevation-1"
            :search="search"
            item-key="liqDesProcId"
            show-expand
            :expanded.sync="expanded"
            :loading="isLoading"
            :sort-desc="true"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="editProcDesregulacion()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canSee">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    v-if="item.estadoId != 'A'"
                    @click="
                      verProcesDesregulacion(
                        item.liqDesProcId,
                        item.periodo,
                        item.osNom
                      )
                    "
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver detalle del proceso</span>
              </v-tooltip>
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    v-if="item.estadoId === 'R'"
                    @click="
                      editProcDesregulacion(item, item.osId, item.liqDesProcId)
                    "
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar proceso</span>
              </v-tooltip>

              <v-tooltip left v-if="canAnular">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    v-if="item.estadoId === 'R'"
                    @click="anularProceso(item.liqDesProcId)"
                  >
                    {{ anuladoIcon }}
                  </v-icon>
                </template>
                <span>Anular proceso</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    v-if="
                      item.estadoId != 'A' &&
                        (item.estadoId == 'C'
                          ? canOpenProceso
                          : canCloseProceso)
                    "
                    @click="
                      changeEstadoProceso(item.liqDesProcId, item.estadoId)
                    "
                  >
                    {{ item.estadoId == "C" ? openCandado : closeCandado }}
                  </v-icon>
                </template>
                <span>{{
                  item.estadoId == "C" ? "Abrir proceso" : "Cerrar proceso"
                }}</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong style="padding-left: 18px">Movimientos:</strong>
                <li v-for="x in item.movimientos" :key="x.liqDesProcMovId">
                  <b>Usuario:</b>
                  {{ x.usuario }} <b>- Estado:</b> {{ x.estado }}
                  <b>- Fecha:</b>
                  {{ x.fecha }}
                </li>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>

    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="80%"
      @keydown.esc="closeModalEdit"
      persistent
    >
      <EditProcesoDesregulacion
        :convenioObject="convenioObject"
        @closeModalEdit="closeModalEdit"
        :osId="osId"
        :liqDesProcIdProp="liqDesProcId"
      ></EditProcesoDesregulacion
    ></v-dialog>
    <v-dialog
      v-if="openModalVer"
      v-model="openModalVer"
      max-width="80%"
      @keydown.esc="closeModalVer"
      persistent
    >
      <VerProcesoDesregulacion
        :liqDesProcId="liqDesProcId"
        :periodo="periodo"
        :convenio="convenio"
        @closeModalVer="closeModalVer"
      ></VerProcesoDesregulacion
    ></v-dialog>
    <DeleteDialog
      :titleProp="titleEstado"
      :maxWidth="'40%'"
      :confirmButtonText="'Confirmar'"
      :openDelete.sync="showEstadoModal"
      @onDeleteItem="confirmChangeEstado()"
    />
    <DeleteDialog
      :titleProp="titleAnular"
      :maxWidth="'30%'"
      :confirmButtonText="'Anular'"
      :openDelete.sync="showAnularModal"
      @onDeleteItem="confirmAnular()"
    />
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import EditProcesoDesregulacion from "@/components/modules/convenios/procesosDesregulacion/EditProcesoDesregulacion.vue";
import VerProcesoDesregulacion from "@/components/modules/convenios/procesosDesregulacion/VerProcesoDesregulacion.vue";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "ConsultaProcesosDesregulacion",
  components: {
    PageHeader,
    DeleteDialog,
    FiltersSelected,
    Ayuda,
    EditProcesoDesregulacion,
    VerProcesoDesregulacion
  },
  directives: { mask },
  data: vm => ({
    expanded: [],
    periodoDesde: null,
    periodoHasta: null,
    convenioSelected: null,
    convenios: [],
    convenioObject: {},
    osId: null,
    liqDesProcId: null,
    title: enums.titles.CONSULTA_PROC_DESREGULACION,
    editIcon: enums.icons.EDIT,
    seeIcon: enums.icons.SEE,
    openCandado: enums.icons.OPEN_CANDADO,
    closeCandado: enums.icons.CLOSE_CANDADO,
    searchIcon: enums.icons.SEARCH,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    anuladoIcon: enums.icons.CIRCLE_NONE,
    showEstadoModal: false,
    titleAnular: "¿Desea anular el proceso?",
    titleEstado: "",
    procIdCambioEstado: null,
    nuevoEstadoId: null,
    showExpand: false,
    openModalEdit: false,
    showHelp: false,
    optionCode: enums.webSiteOptions.PROCESOS_DESREGULACION,
    showIcon: true,
    rules: rules,
    settingsIcon: enums.icons.SETTINGS,
    canConfig: false,
    canCloseProceso: false,
    canOpenProceso: false,
    isLoading: false,
    itemsProcesosDesreg: [],
    search: "",
    headers: [
      {
        text: "N° proceso",
        value: "liqDesProcId",
        sortable: true
      },
      {
        text: "Convenio",
        value: "osNom",
        sortable: false
      },
      {
        text: "Período",
        value: "periodo",
        sortable: false
      },
      {
        text: "Total",
        value: "total",
        sortable: false,
        align: "end"
      },
      {
        text: "Comisión",
        value: "comision",
        sortable: false,
        align: "end"
      },
      {
        text: "Neto",
        value: "neto",
        sortable: false,
        align: "end"
      },
      {
        text: "Estado",
        value: "estado",
        sortable: false
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      { text: "", value: "data-table-expand", align: "end" }
    ],
    showFilters: true,
    changeEstado: true,
    filtersApplied: [],
    isFormValid: true,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    allowedActions: null,
    canCreate: false,
    canAnular: false,
    canSee: false,
    canEdit: false,
    openModalVer: false,
    estadosPro: null,
    showAnularModal: false,
    idToAnular: null,
    convenio: null,
    idToAnular: null
  }),

  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    const traerPeriodo = this.periodoActual();
    this.periodoHasta = traerPeriodo;
    const traerLastPeriodo = this.lastPeriodo();
    this.periodoDesde = traerLastPeriodo;
    this.setPermisos();
    this.setConvenios();
    this.applyFilters();
  },
  methods: {
    ...mapActions({
      getConvenios: "afiliaciones/getConvenios",
      fetchEstadosProcesosDesregulacion:
        "convenios/fetchEstadosProcesosDesregulacion",
      setAlert: "user/setAlert",
      fetchConsultaProcesos: "convenios/fetchConsultaProcesos",
      cambiaEstadoProcesoDesregulacion:
        "convenios/cambiaEstadoProcesoDesregulacion",
      fetchConveniosByFilters: "convenios/fetchConveniosByFilters",
      anulaProcesoDesregulacion: "convenios/anulaProcesoDesregulacion"
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVO_PROCESO_DESREGULACION:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.VER_PROCESO_DESREGULACION:
            this.canSee = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_PROCESO_DESREGULACION:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .ANULAR_PROCESO_DESREGULACION:
            this.canAnular = true;
            break;
          case enums.modules.adminSistema.permissions
            .CONFIG_CONCEPTO_DESREGULACION:
            this.canConfig = true;
            break;
          case enums.modules.adminSistema.permissions.ABRIR_PROCESO_DESREG:
            this.canOpenProceso = true;
            break;
          case enums.modules.adminSistema.permissions.CERRAR_PROCESO_DESREG:
            this.canCloseProceso = true;
            break;
          default:
            break;
        }
      });
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    periodoActual() {
      let now = new Date();
      let month = ("0" + (now.getMonth() + 1)).slice(-2);
      let periodoNow = now.getFullYear() + month;

      return periodoNow;
    },
    lastPeriodo() {
      let now = new Date();
      let one = new Date(now.setMonth(now.getMonth() - 1));
      let oneAño = one.getFullYear();
      let oneMes = one.getMonth() + 1;
      oneMes < 10 ? (oneMes = `0${oneMes}`) : oneMes;
      let periodoLast = `${oneAño}${oneMes}`;
      return periodoLast
    },
    changeEstadoProceso(id, estado) {
      this.showEstadoModal = true;
      this.procIdCambioEstado = id;
      this.nuevoEstadoId = estado == "R" ? "C" : "R";
      this.titleEstado =
        estado == "R"
          ? "El estado del proceso pasará a CERRADO"
          : "El estado del proceso pasará a REVISIÓN";
    },
    async confirmChangeEstado() {
      const data = {
        LiqDesProcId: this.procIdCambioEstado,
        Estado: this.nuevoEstadoId
      };
      const response = await this.cambiaEstadoProcesoDesregulacion(data);
      if (response.status === 200) {
        this.setAlert({
          type: "success",
          message: "Estado modificado con éxito"
        });
        this.showEstadoModal = false;
      }
      this.applyFilters();
    },
    async setConvenios() {
      const data = await this.getConvenios();
      this.convenios = data;
    },
    editProcDesregulacion(item, osId, liqDesProcId) {
      this.openModalEdit = true;
      this.convenioObject = item;
      this.osId = osId;
      this.liqDesProcId = liqDesProcId;
    },
    closeModalEdit() {
      this.openModalEdit = false;
    },
    verProcesDesregulacion(liqDesProcId, periodo, osNom) {
      this.openModalVer = true;
      this.liqDesProcId = liqDesProcId;
      this.periodo = periodo;
      this.convenio = osNom;
    },
    closeModalVer() {
      this.openModalVer = false;
    },
    async applyFilters() {
      this.isLoading = true;
      this.customizeFiltersApplied();
      const data = {
        convenioId:
          this.convenioSelected != null ? this.convenioSelected.id : null,
        periodoDesde: this.periodoDesde,
        periodoHasta: this.periodoHasta
      };
      try {
        this.itemsProcesosDesreg = [];
        const resp = await this.fetchConsultaProcesos(data);
        this.itemsProcesosDesreg = resp;
        this.isLoading = false;
        this.showFilters = false;
      } catch {
        this.isLoading = false;
      }
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.convenioSelected) {
        this.filtersApplied.splice(1, 0, {
          key: "convenio",
          label: "Convenio",
          model: this.convenioSelected.value
        });
      }
      if (this.periodoDesde) {
        this.filtersApplied.splice(2, 0, {
          key: "periodoDesde",
          label: "Período desde",
          model: this.periodoDesde
        });
      }
      if (this.periodoHasta) {
        this.filtersApplied.splice(3, 0, {
          key: "periodoHasta",
          label: "Período hasta",
          model: this.periodoHasta
        });
      }
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    goToConfigProcesoDesregulacion() {
      this.$router.push({
        name: "ConfigProcesosDesregulacion"
      });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    anularProceso(id) {
      this.showAnularModal = true;
      this.idToAnular = id;
    },
    async confirmAnular() {
      const response = await this.anulaProcesoDesregulacion({
        liqDesProcId: this.idToAnular
      });
      if (response.status === 200) {
        this.showAnularModal = false;
        this.setAlert({
          type: "success",
          message: "El proceso ha sido anulado."
        });
      }
      this.applyFilters();
    }
  }
};
</script>

<style lang="scss" scoped>
.my-row {
  max-height: 80px;
}
.v-toolbar__content {
  height: 40px !important;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.icon-readonly {
  cursor: context-menu;
}
.my-tooltip {
  white-space: pre-line;
}
::v-deep label {
  margin-bottom: 0;
}
.no-upper-case {
  text-transform: none;
}
</style>
