<template>
  <v-card>
    <v-container>
      <v-card-title class="p-0">
        <span class="pl-1 primary--text">{{ newTitle }}</span>
      </v-card-title>

      <v-card-text class="py-0 px-0">
        <v-form
          v-model="isFormValid"
          ref="form-nuevo-proceso-deta"
          form="form-nuevo-proceso-deta"
          id="form-nuevo-proceso-deta"
          @submit.prevent="saveConcepto()"
        >
          <v-row>
            <!-- Concepto -->
            <v-col cols="12" class="pb-0">
              <v-autocomplete
                v-model="concepto"
                :items="conceptos"
                label="Concepto"
                outlined
                return-object
                item-text="conceptoNom"
                item-value="liqDesConcId"
                @change="!concepto ? ((cuiles = null), (importe = null)) : ''"
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- cuiles -->
            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="cuiles"
                label="Cantidad de CUILES"
                type="text"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :disabled="!concepto"
                dense
                outlined
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <!-- importe -->
            <v-col cols="6" class="pb-0 ">
              <currency-input
                label="Importe"
                v-model="importe"
                dense="false"
                :disabled="!cuiles"
                @change="setCalculoComision"
                outlined="false"
                :options="currencyOptions"
                :rules="rules.required.concat([rules.maxLength(importe, 18)])"
              >
                <template>
                  <v-progress-circular
                    indeterminate
                    size="28"
                    v-if="comisionLoading"
                    color="primary"
                  ></v-progress-circular>
                </template>
              </currency-input>
            </v-col>
          </v-row>
          <v-row>
            <!-- % comision -->
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model="porcentajeComision"
                :append-icon="porcentajeIcon"
                hint="Porcentaje"
                dense
                label="Porcentaje de comisión"
                outlined
                filled
                disabled
              >
                <template v-slot:append>
                  <v-progress-circular
                    indeterminate
                    size="28"
                    v-if="comisionLoading"
                    color="primary"
                  ></v-progress-circular>
                </template>
              </v-text-field>
            </v-col>
            <!-- comision -->
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model="comision"
                label="Comisión"
                hint="Comisión"
                dense
                filled
                disabled
                outlined
              >
                <template>
                  <v-progress-circular
                    indeterminate
                    size="28"
                    v-if="comisionLoading"
                    color="primary"
                  ></v-progress-circular>
                </template>
              </v-text-field>
            </v-col>

            <!-- neto -->
            <v-col cols="4" class="py-0">
              <currency-input
                label="Importe neto"
                v-model="neto"
                dense="false"
                filled
                disabled
                outlined="false"
                :options="currencyOptions"
                :rules="neto === 0 ? [] : rules.required"
              >
                <template v-slot:append>
                  <v-progress-circular
                    indeterminate
                    size="28"
                    v-if="comisionLoading"
                    color="primary"
                  ></v-progress-circular>
                </template>
              </currency-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form-nuevo-proceso-deta"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import { mask } from "vue-the-mask";

export default {
  name: "ConceptoProcesoDesregulacion",
  directives: { mask },
  props: {
    propProcDesregId: {
      type: Number
    },
    osId: { type: Number }
  },
  components: { CurrencyInput },
  data: () => ({
    porcentajeIcon: enums.icons.PERCENT_OUTLINE,
    isFormValid: false,
    newTitle: enums.titles.ADD_CONCEPTO,
    rules: rules,
    concepto: null,
    conceptos: [],
    importe: null,
    cuiles: null,
    porcentajeComision: 0,
    comision: 0,
    neto: null,
    comisionLoading: false,
    currencyNetoOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "hidden",
      valueRange: {
        min: 0,
        max: 999999999999999.99
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: false
    },
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "hidden",
      valueRange: {
        min: 0,
        max: 999999999999999.99
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: false,
      traerCalculos: {},
      conceptId: null
    }
  }),
  created() {
    this.traerConceptos();
  },
  methods: {
    ...mapActions({
      calcularComisionNuevoProcesoDeta:
        "convenios/calcularComisionNuevoProcesoDeta",
      getConceptosNuevoConcepto: "convenios/getConceptosNuevoConcepto",
      setAlert: "user/setAlert",
      agregarConceptoProcesoDeta: "convenios/agregarConceptoProcesoDeta"
    }),
    async traerConceptos() {
      const resp = await this.getConceptosNuevoConcepto(this.propProcDesregId);
      this.conceptos = resp;
    },
    async setCalculoComision() {
      this.comisionLoading = true;
      if (this.concepto != null && this.importe != null) {
        const data = {
          importe: this.importe,
          osId: this.osId,
          liqDesConcId: this.concepto.liqDesConcId
        };
        const resp = await this.calcularComisionNuevoProcesoDeta(data);
        this.traerCalculos = resp;
        this.porcentajeComision = this.traerCalculos.comisionPorc;
        this.comision = this.traerCalculos.comisionMonto;
        this.neto = this.traerCalculos.neto;
      }
      this.comisionLoading = false;
    },
    // Función que valida el formato de el campo porcentaje.
    validateFormat() {
      let dotCount = 0;
      let afterComa = 0;
      let arrayString = this.porcentajeComision.toString();
      for (let index = 0; index < arrayString.length; index++) {
        const element = arrayString[index];
        if (dotCount == 1) {
          afterComa++;
        }
        if (dotCount == 0 && index > 5 && element != "." && afterComa == 0) {
          return ["Formato incorrecto"];
        }
        if (element == ".") {
          dotCount++;
          if (index == 0 || index > 5) {
            return ["Formato incorrecto"];
          }
        }
        if (afterComa > 1) {
          return ["Formato incorrecto"];
        }
        if (element == "/" || dotCount > 1) {
          return ["Formato incorrecto"];
        }
      }
      return [];
    },
    async saveConcepto() {
      const data = {
        liqDesProcId: this.propProcDesregId,
        liqDesConcId: this.concepto.liqDesConcId,
        cuiles: parseInt(this.cuiles),
        importe: this.importe,
        porceComision: this.porcentajeComision,
        comision: parseFloat(this.comision),
        neto: parseFloat(this.neto)
      };
      const res = await this.agregarConceptoProcesoDeta(data);
      if (res.status === 200) {
        this.setAlert({
          type: "success",
          message: "Concepto agregado con éxito"
        });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeModalNuevoConcepto");
    }
  }
};
</script>

<style></style>
