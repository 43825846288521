<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0 pb-0">
            <span class="primary--text">{{ titleEdit }}</span>
          </v-card-title>
          <v-card class=" mb-4 pt-0" max-width="1200" outlined>
            <v-container class="pt-0">
              <v-row justify="end"> </v-row>
              <v-row>
                <v-col cols="12" align="left" class="pt-0 pb-0">
                  <FiltersSelected
                    :filters="filtersApplied"
                    v-if="!showFilters"
                  />
                </v-col>
              </v-row>
              <v-expand-transition mode="out-in">
                <v-form
                  v-show="showFilters"
                  v-model="isFormValid"
                  ref="filter-proceso"
                  id="filter-proceso"
                  @submit.prevent="sendProceso()"
                >
                  <!-- Filtros comunes -->
                  <v-row>
                    <!-- Convenio -->
                    <v-col cols="4" md="5" class="pb-0">
                      <div v-if="liqDesProcId == null">
                        <v-autocomplete
                          v-model="convenioNuevo"
                          :items="convenios"
                          label="Convenio"
                          return-object
                          item-text="value"
                          item-value="id"
                          outlined
                          clearable
                          :rules="rules.required"
                          dense
                          :disabled="liqDesProcId != null"
                        >
                        </v-autocomplete>
                      </div>
                      <div v-else>
                        <v-text-field
                          v-model="convenioSelected"
                          label="Convenio"
                          filled
                          disabled
                          readonly
                          outlined
                          clearable
                          dense
                        >
                        </v-text-field>
                      </div>
                    </v-col>
                    <!-- periodo -->
                    <v-col cols="3" md="3" class="pb-0">
                      <v-text-field
                        v-model="periodoProp"
                        label="Período"
                        outlined
                        :rules="rules.required.concat(rules.periodoYyyyMm)"
                        dense
                        hint="Formato AAAAMM"
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                        :disabled="liqDesProcId != null"
                        :filled="liqDesProcId != null"
                        :readonly="liqDesProcId != null"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <!-- boton procesar -->
                    <v-col cols="4" md="4" sm="4" class="pt-5 text-right">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            v-if="!liqDesProcId"
                            size="28"
                            @click="resetForm"
                          >
                            {{ clearFiltersIcon }}
                          </v-icon>
                        </template>
                        <span>Limpiar filtros</span>
                      </v-tooltip>
                      <v-btn
                        color="primary"
                        :disabled="!isFormValid || btnProcLoading"
                        elevation="2"
                        small
                        type="submit"
                        form="filter-proceso"
                        :loading="btnProcLoading"
                      >
                        Procesar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-expand-transition>
            </v-container>
          </v-card>
          <v-card>
            <v-data-table
              :headers="headers"
              :items="itemsRegistroProcesosDesreg"
              class="elevation-1"
              :search="search"
              :loading="isLoading"
              :sort-desc="true"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-col cols="10">
                      <v-text-field
                        v-model="search"
                        :append-icon="searchIcon"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" align="end" v-if="canCreateNewConcept">
                      <v-btn
                        color="primary"
                        @click="nuevoConcepto()"
                        class="to-right"
                        :disabled="liqDesProcId == null"
                      >
                        Nuevo
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="verConcepto(item)"
                      >
                        {{ seeIcon }}
                      </v-icon>
                    </template>
                    <span>Ver conceptos</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-if="!item.esConcManual"
                        @click="reprocesarConcepto(item.liqDesConcId)"
                        v-on="on"
                      >
                        {{ reloadIcon }}
                      </v-icon>
                    </template>
                    <span>Reprocesar concepto</span>
                  </v-tooltip>

                  <v-tooltip left v-if="canDeleteConcepto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="deleteConcepto(item.liqDesDetaId)"
                      >
                        {{ deleteIcon }}
                      </v-icon>
                    </template>
                    <span>Eliminar concepto</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined @click="closeModal"> Cerrar </v-btn>
          </v-card-actions>
          <v-dialog
            v-if="openModalNuevoConcepto"
            v-model="openModalNuevoConcepto"
            max-width="50%"
            @keydown.esc="closeModalNuevoConcepto"
            persistent
          >
            <ConceptoProcesoDesregulacion
              :propProcDesregId="liqDesProcId"
              :osId="osId != null ? osId : convenioNuevo.id"
              @closeModalNuevoConcepto="closeModalNuevoConcepto"
            ></ConceptoProcesoDesregulacion>
          </v-dialog>
          <v-dialog
            v-if="openModalVerConceptos"
            v-model="openModalVerConceptos"
            max-width="80%"
            persistent
            @keydown.esc="openModalVerConceptos = false"
          >
            <VerConceptoProcesoDesreg
              @closeAndReload="openModalVerConceptos = false"
              :detalleConcepto="detalleConcepto"
              :liqDesDetaId="liqDesDetaId"
              :tipoProceso="tipoProcesoSelected"
              :esConceptoManual="esConceptoManual"
            ></VerConceptoProcesoDesreg>
          </v-dialog>
        </v-col>
      </v-row>
      <DeleteDialog
        :titleProp="titleDelete"
        :maxWidth="'30%'"
        :confirmButtonText="'Eliminar'"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="confirmDelete()"
      />
    </v-container>
  </v-card>
</template>
<script>
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import ConceptoProcesoDesregulacion from "@/components/modules/convenios/procesosDesregulacion/ConceptoProcesoDesregulacion.vue";
import VerConceptoProcesoDesreg from "@/components/modules/convenios/procesosDesregulacion/VerConceptoProcesoDesreg.vue";

export default {
  name: "EditProcesoDesregulacion",
  components: {
    FiltersSelected,
    ConceptoProcesoDesregulacion,
    VerConceptoProcesoDesreg,
    DeleteDialog
  },
  directives: { mask },
  props: {
    convenioObject: {
      type: Object,
      default: null
    },
    osId: {
      type: Number,
      default: null
    },
    liqDesProcIdProp: {
      type: Number,
      default: null
    }
  },
  data: vm => ({
    titleNew: enums.titles.NUEVO_PROCESO_DESREG,
    titleEdit: enums.titles.EDITAR_PROCESO_DESREGULACION,
    titleVer: enums.titles.VER_PROCESO_DESREG,
    titleDelete: "¿Desea eliminar el concepto?",
    deleteIcon: enums.icons.DELETE,
    reloadIcon: enums.icons.RELOAD,
    seeIcon: enums.icons.SEE,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    searchIcon: enums.icons.SEARCH,
    optionCode: enums.webSiteOptions.PROCESOS_DESREGULACION,
    tipoProcesoSelected: null,
    convenioSelected: null,
    convenioNuevo: null,
    convenios: [],
    btnProcLoading: false,
    showHelp: false,
    showDeleteModal: false,
    modalExportarExcel: false,
    dialog: false,
    loadingProceso: false,
    openModalNuevoConcepto: false,
    itemsNuevoMovMasivoPadrones: [],
    filtersApplied: [],
    canDeleteConcepto: false,
    canCreateNewConcept: false,
    showFilters: true,
    periodoProceso: "",
    isFormValid: false,
    isLoading: false,
    search: "",
    periodoProp: "",
    tipoProcesos: [],
    liqDesDetaId: null,
    detalleConcepto: {
      liqDesConc_Nom: null,
      liqDelconcAut_id: null,
      esVigente: null,
      signo: null,
      orden: null
    },
    headers: [
      {
        text: "Concepto",
        value: "liqDesConcNom",
        sortable: false,
        align: "start"
      },
      {
        text: "Cuiles",
        value: "cuiles",
        sortable: false,
        align: "center"
      },
      {
        text: "Importe",
        value: "importe",
        sortable: false,
        align: "end"
      },
      {
        text: "Porcentaje comisión",
        value: "porceComision",
        sortable: false,
        align: "end"
      },
      {
        text: "Comisión",
        value: "comision",
        sortable: false,
        align: "end"
      },
      {
        text: "Neto",
        value: "neto",
        sortable: false,
        align: "end"
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    rules: rules,
    itemsRegistroProcesosDesreg: [],
    canCreateNuevo: false,
    canEdit: false,
    allowedActions: null,
    openModalVerConceptos: false,
    idToDelete: null,
    propProcDesregId: null,
    liqDesProcId: null
  }),
  async created() {
    this.setConvenios();
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.setPermisos();
    if (this.liqDesProcIdProp) {
      this.liqDesProcId = this.liqDesProcIdProp;
      this.setProcesoDesreg();
    } else {
      this.newProcesoDesreg();
    }
  },

  methods: {
    ...mapActions({
      getProcesosDeta: "convenios/getProcesosDeta",
      quitarConceptoProcesoDeta: "convenios/quitarConceptoProcesoDeta",
      reprocesarConceptoDesregulacion:
        "convenios/reprocesarConceptoDesregulacion",
      agregarConceptoProcesoDeta: "convenios/agregarConceptoProcesoDeta",
      getConvenios: "afiliaciones/getConvenios",
      nuevoProcesoDesregulacion: "convenios/nuevoProcesoDesregulacion",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVO_CONCEPTO_PROC_DESREGULACION:
            this.canCreateNewConcept = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_CONCEPTO_PROC_DESREGULACION:
            this.canDeleteConcepto = true;
            break;
          default:
            break;
        }
      });
    },
    async setConvenios() {
      const data = await this.getConvenios();
      this.convenios = data;
    },
    async newProcesoDesreg() {
      this.titleEdit = this.titleNew;
    },
    async setProcesoDesreg() {
      this.isLoading = true;
      const data = await this.getProcesosDeta(this.liqDesProcId);
      this.itemsRegistroProcesosDesreg = data.procesosDeta;
      this.isLoading = false;
      if (this.convenioObject != null) {
        this.convenioSelected = this.convenioObject.osNom;
        this.periodoProp = this.convenioObject.periodo;
      }
    },

    async sendProceso() {
      this.btnProcLoading = true;
      this.isLoading = true;

      const data = {
        liqDesProcId: this.liqDesProcId ? this.liqDesProcId : null,
        osId: this.osId ? this.osId : this.convenioNuevo.id,
        periodo: this.periodoProp
      };
      try {
        const res = await this.nuevoProcesoDesregulacion(data);
        // this.itemsRegistroProcesosDesreg = this.itemsRegistroProcesosDesreg.push(res.data)
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Procesado con éxito" });
        }
        this.liqDesProcId = res.data.data.liqDesProcId;
        this.convenioSelected =
          this.convenioObject == null
            ? this.convenioNuevo.value
            : this.convenioSelected;
        await this.setProcesoDesreg();
        this.btnProcLoading = false;
        this.isLoading = false;
      } catch (error) {
        this.btnProcLoading = false;
        this.isLoading = false;
      }
    },
    async reprocesarConcepto(id) {
      this.isLoading = true;
      const data = {
        liqDesProcId: this.liqDesProcId,
        liqDesConcId: id
      };
      const res = await this.reprocesarConceptoDesregulacion(data);
      if (res.status === 200) {
        this.setAlert({
          type: "success",
          message: "Concepto reprocesado con éxito"
        });
        this.setProcesoDesreg();
        this.isLoading = false;
      }
    },
    verConcepto(item) {
      this.openModalVerConceptos = true;
      this.liqDesDetaId = item.liqDesDetaId;
      this.tipoProcesoSelected = item.tipoProceso.trim();
      this.esConceptoManual = item.esConcManual;
      this.detalleConcepto.liqDesConc_Nom = item.detalleConcepto.nombre.trim();
      this.detalleConcepto.liqDelconcAut_id =
        item.detalleConcepto.liqDesconcAutId;
      this.detalleConcepto.esVigente = item.detalleConcepto.esVigente;
      this.detalleConcepto.signo = item.detalleConcepto.signo;
      this.detalleConcepto.orden = item.detalleConcepto.orden;
    },
    nuevoConcepto() {
      this.openModalNuevoConcepto = true;
    },
    closeModalNuevoConcepto() {
      this.openModalNuevoConcepto = false;
      this.setProcesoDesreg();
    },
    closeModal() {
      this.liqDesProcId = null;
      this.$emit("closeModalEdit");
    },
    resetForm() {
      this.$refs["filter-proceso"].reset();
    },
    deleteConcepto(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.quitarConceptoProcesoDeta({
        liqDesConcId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({
          type: "success",
          message: "Concepto eliminado con éxito."
        });
      }
      this.setProcesoDesreg();
    }
  }
};
</script>

<style scoped>
.fontColor {
  font-weight: bold !important;
  color: rgb(0, 0, 0) !important;
  text-align: center;
}
.invalidConfigText {
  color: red;
}
.fontsize {
  font-size: 12px;
}
.no-upper-case {
  text-transform: none;
}
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}

</style>
